/**
 * Inhaltsverzeichnis
 * 	1. Number
 * 		1.1 Math Clamp
 * 		1.2 Is Even
 * 		1.3 In Range
 * 	2. HTML Element
 * 		2.1 Find
 * 		2.2 Find All
 * 		2.3 Find Parent
 * 
 */


/* ================================================== */
/* 1. Logic Functions */
/* -------------------------------------------------- */
// #=#=#=#=#=# 1.1 Math Clamp #=#=#=#=#=#
/**
 * Returns a number whose value is limited to the given range.
 * @param		min				The lower boundary of the output range
 * @param		max				The upper boundary of the output range
 * @returns						A number in the range [min, max]
 */
Number.prototype.clamp		= function(
	min:number,
	max:number
):number {
	return Math.min(Math.max(this, min), max);
};


// #=#=#=#=#=# 1.2 Is Even #=#=#=#=#=#
/**
 * Returns true if the number is even, otherwise false.
 */
Number.prototype.isEven		= function(
): boolean {
	return this % 2 === 0;
};


// #=#=#=#=#=# 1.3 In Range #=#=#=#=#=#
/**
 * Returns true if the number is within the range, otherwise false.
 */
Number.prototype.inRange	= function(
	min:number,
	max:number
): boolean {
	return this >= min && this <= max;
};


/* ================================================== */
/* 2. HTML Element */
/* -------------------------------------------------- */
// #=#=#=#=#=# 2.1 Find #=#=#=#=#=#
/**
 * Returns the first Element within the document that matches the specified selector, or group of selectors.
 * @param		selector		A string containing one or more CSS selectors to match
 * @returns						Returns the first Element within the document that matches the specified selector, or group of selectors
 */
HTMLElement.prototype.find	= function(
	selector:string
):HTMLElement|null {
	return this.querySelector(selector);
};


// #=#=#=#=#=# 2.2 Find All #=#=#=#=#=#
/**
 * Returns a list of the elements within the document that match the specified group of selectors.
 * @param		selector		A string containing one or more CSS selectors to match
 * @returns						Returns a list of the elements within the document that match the specified group of selectors
 */
HTMLElement.prototype.findAll	= function(
	selector:string
): NodeList {
	return this.querySelectorAll(selector);
};


// #=#=#=#=#=# 2.3 Find Parent #=#=#=#=#=#
/**
 * Bubbles up from the current Element and checks for a selector matching Parent
 * @param		selector		A selector for matching/finding a Parent Node
 * @returns						Returns the Element if a matching one was found, otherwise null
 */
HTMLElement.prototype.findParent	= function(
	selector:string
) {
	// Set current Element as starting point
	let element		= this;
	
	// Iterate all ParentNodes
	while(element.parentElement) {
		// Select current Parent
		const parent	=	element.parentElement;

		// Return matching Parent
		if(parent.matches(selector)) {
			return parent;
		}

		// Select next Parent
		else {
			element	= parent;
		}
	}

	// No Matching Element Found
	return null;
};


/* ================================================== */
/* 3. Script Element */
/* -------------------------------------------------- */
// #=#=#=#=#=# 3.1 Get JSON #=#=#=#=#=#
/**
 * Tries to parse the innerHTML of the Element as JSON
 * @returns			Returns the parsed JSON Data or undefined if the Element is empty or not declared as JSON
 */
HTMLScriptElement.prototype.getJSON	= function(
): Array<any>|Object|undefined {
	// #==== Guard ====#
	// Check if the Element has a value and is declared as JSON
	switch(true) {
		case this.innerHTML === '':
			console.warn('No Data in Element:', this);
			return;
		case this.type !== 'application/json':
			console.warn('Type of Element was not declared as JSON:', this);
			return;
	}


	// #==== Return ====#
	// Parse and return the JSON Data
	return JSON.parse(this.innerHTML);
}


// #=#=#=#=#=# 3.2 Store JSON #=#=#=#=#=#
/**
 * Tries to store data to the Elements innerHTML as JSON
 * @param		data		The data to store in the Element as JSON
 */
HTMLScriptElement.prototype.storeJSON	= function(
	data:Array<any>|Object
): void {
	// #==== Convert ====#
	// Try to convert the data
	const	json	= JSON.stringify(data);


	// #==== Guard ====#
	// Check if the data could be converted to a valid JSON string
	if(json === undefined) {
		console.warn('Data couldnt be converted to a valid JSON string:', data, this);
		return;
	}


	// #==== Store ====#
	// Rewrite the Element Type to JSON
	this.type			= 'application/json';
	// Store the JSON Data to the innerHTML
	this.innerHTML		=	json;
}


/* ================================================== */
/* 4. HTML Collection */
/* -------------------------------------------------- */
// #=#=#=#=#=# 4.1 Find #=#=#=#=#=#
/**
 * Returns the first Element within the HTML Collection that matches the specified selector.
 * @param		selector		A string containing one or more CSS selectors to match
 * @returns						Returns the first Element matching the specified selector
 */
HTMLCollection.prototype.find	= function(
	selector:string
): HTMLElement|void {
	for(const element of this) {
		if(element.matches(selector)) {
			return element;
		}
	}
}


// #=#=#=#=#=# 4.2 Find All #=#=#=#=#=#
/**
 * Returns a list of the elements within the HTML Collection that match the specified group of selectors.
 * @param		selector		A string containing one or more CSS selectors to match
 * @returns						Returns a list of the elements within the HTML Collection that match the specified group of selectors
 */
HTMLCollection.prototype.findAll	= function(
	selector:string
): HTMLElement[] {
	// Create new Array
	const elements: HTMLElement[]		=	[];

	// Iterate all Elements
	for(const element of this as HTMLElement[]) {
		if(element.matches(selector)) {
			elements.push(element);
		}
	}

	// Return Array
	return elements;
}